import React, { useEffect } from "react"
import { navigate } from "gatsby"
import Layout from "../../../template/Layout"

/**
 * Material
 */
import Head from "../../../head"
import { SectionTitle } from "../../common/SectionTitle"
import { ContactContainer } from "../common/ContactContainer"
import { ContactDescription } from "../common/ContactDescription"
import ContactRecruitDetail from "./ContactRecruitDetail"
import { useRecruitsFormContext } from "../../../utlis/FormValues"
import {
  Props as BreadcrumbsData,
} from "../../common/Breadcrumbs"
import { invalidRequired } from "../../../utlis/check"

/**
 * Props
 */
type Props = {
  breadcrumbsData: BreadcrumbsData["values"]
}

/**
 * Component
 */
export const RecruitConfirm: React.VFC<Props> = (props) => {
  const { state } = useRecruitsFormContext();

  useEffect(() => {
    if (invalidRequired(state.name) || invalidRequired(state.email)) {
      navigate('../');
    }
  }, []);

  return (
    <Layout sidebar={false} breadcrumbsData={props.breadcrumbsData}>
      <Head />
      <ContactContainer>
        <SectionTitle title='CONTACT' subTitle='お問い合わせ' />
        <ContactDescription title="採用エントリー" step={2} />
        <ContactRecruitDetail
          name={state.name || ''}
          email={state.email || ''}
          resumeFiles={state.resumeFiles || []}
          workFiles={state.workFiles || []}
          webSites={state.webSites || []}
          point={state.point || ''}
          other={state.other || ''}
        />
      </ContactContainer>
    </Layout>
  )
}

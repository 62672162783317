import React from "react"

/**
 * Material
 */
import { RecruitConfirm } from "../../../components/contact/recruit/confirm"

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `CONTACT`, path: `contact` },
  { text: `採用エントリー`, path: `contact/recruit` },
]

/**
 * Component
 */
export default function Home() {
  return (
    <RecruitConfirm breadcrumbsData={breadcrumbsData} />
  )
}


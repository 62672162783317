import React, { useState } from "react"
import styled from "styled-components"
import { ContactService } from "../../../api/contacts"
import { ContactInputGroupConfirm } from "../common/ContactInputGroupConfirm"
import { ContactFormSendButton } from "../common/ContactFormSendButton"
import { uploadFile, ContactFileUploadForm } from "../common/ContactFileUploadForm"
import { contact } from "../../../data/contact"

/**
 * Element & Styles
 */
const Form = styled.form`
`

const FormNode = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`

const HiddenItem = styled.input`
`

/**
 * Props
 */
type State = {
  name: string;
  email: string;
}

type Options = {
  resumeFiles: Array<HTMLInputElement>;
  workFiles: Array<HTMLInputElement>;
  webSites: Array<string>;
  point: string;
  other: string;
}

type Props = State & Partial<Options>

/**
 * Component
 */
const ContactRecruitDetail: React.VFC<Props> = (props) => {
  const [isDisabled, setDisabled] = useState(false);

  return (
    <>
      <Form
        id='form'
        name='採用エントリー'
        method='POST'
        encType='multipart/form-data'
        netlify-honeypot='bot-field'
        data-netlify='true'
        action='/contact/recruit/accepted'
        onSubmit={
          (e) => {
            return uploadFile(e);
          }
        }
      >
        <FormNode>
          <HiddenItem type='hidden' name='bot-field' />
          <HiddenItem type='hidden' name='form-name' value='採用エントリー' />

          <ContactInputGroupConfirm
            label={contact.recruit.name.title}
            values={[props.name]}
          />

          <ContactInputGroupConfirm
            label={contact.recruit.email.title}
            values={[props.email]}
          />

          <ContactInputGroupConfirm
            label={contact.recruit.resumeFiles.title}
            values={props.resumeFiles}
          />

          <ContactInputGroupConfirm
            label={contact.recruit.workFiles.title}
            values={props.workFiles}
          />

          <ContactInputGroupConfirm
            label={contact.recruit.webSites.title}
            values={props.webSites}
          />

          <ContactInputGroupConfirm
            label={contact.recruit.point.title}
            values={[props.point]}
          />

          <ContactInputGroupConfirm
            label={contact.recruit.other.title}
            values={[props.other]}
          />

          <ContactFormSendButton isDisabled={isDisabled} />

        </FormNode>
      </Form>
      <ContactFileUploadForm
        type={"recruit"}
        name={props.name}
        email={props.email}
        files={[...props.workFiles, ...props.resumeFiles]}
        setDisabled={setDisabled}
        uploadService={ContactService.uploadToGoogleDrive}
        callback={() => {
          document.querySelector('form').submit();
        }}
      />
    </>
  )
}

export default ContactRecruitDetail